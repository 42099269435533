import dynamic from "next/dynamic";
import { createRouter } from "nextjs-wagtail";
import NotFoundPage from "pages/404";

export const primaryUrl = process.env.PRIMARY_URL;

export const { CMSPage, getCMSProps, getPreviewProps } = createRouter({
    routes: [
        {
            type: "cms.HomePage",
            component: dynamic(() => import("./components/wagtail/HomePage")),
        },
        {
            type: "cms.AboutPage",
            component: dynamic(() => import("./components/wagtail/AboutPage")),
        },
        {
            type: "cms.MediaLandingPage",
            component: dynamic(
                () => import("./components/wagtail/MediaLandingPage"),
            ),
        },
        {
            type: "cms.MediaCategoryPage",
            component: dynamic(
                () => import("./components/wagtail/MediaCategoryPage"),
            ),
        },
        {
            type: "cms.MediaSocialGridPage",
            component: dynamic(
                () => import("./components/wagtail/MediaSocialGridPage"),
            ),
        },
        {
            type: "cms.MediaPressReleaseLandingPage",
            component: dynamic(
                () =>
                    import("./components/wagtail/MediaPressReleaseLandingPage"),
            ),
        },
        {
            type: "cms.MediaPressReleaseDetailPage",
            component: dynamic(
                () =>
                    import("./components/wagtail/MediaPressReleaseDetailPage"),
            ),
        },
        {
            type: "cms.SupportLandingPage",
            component: dynamic(
                () => import("./components/wagtail/SupportLandingPage"),
            ),
        },
        {
            type: "cms.SupportSubPage",
            component: dynamic(
                () => import("./components/wagtail/SupportSubPage"),
            ),
        },
        {
            type: "cms.DealerLandingPage",
            component: dynamic(
                () => import("./components/wagtail/DealerLandingPage"),
            ),
        },
        {
            type: "cms.DealerLocatorPage",
            component: dynamic(
                () => import("./components/wagtail/DealerLocatorPage"),
            ),
            fetchData: (...props) =>
                import("./components/wagtail/DealerLocatorPage").then(
                    ({ fetchData }) => fetchData(...props),
                ),
        },
        {
            type: "cms.DealerSubPage",
            component: dynamic(
                () => import("./components/wagtail/DealerSubPage"),
            ),
        },
        {
            type: "cms.UtilityPage",
            component: dynamic(
                () => import("./components/wagtail/UtilityPage"),
            ),
        },
        {
            type: "cms.GlossaryPage",
            component: dynamic(
                () => import("./components/wagtail/GlossaryPage"),
            ),
        },
        {
            type: "cms.BlogLandingPage",
            component: dynamic(
                () => import("./components/wagtail/BlogLandingPage"),
            ),
            fetchData: (...props) =>
                import("./components/wagtail/BlogLandingPage").then(
                    ({ fetchData }) => fetchData(...props),
                ),
        },
        {
            type: "cms.BlogCategoryPage",
            component: dynamic(
                () => import("./components/wagtail/BlogCategoryPage"),
            ),
            fetchData: (...props) =>
                import("./components/wagtail/BlogCategoryPage").then(
                    ({ fetchData }) => fetchData(...props),
                ),
        },
        {
            type: "cms.BlogPost",
            component: dynamic(() => import("./components/wagtail/BlogPost")),
        },
        {
            type: "cms.LandingPage",
            component: dynamic(
                () => import("./components/wagtail/LandingPage"),
            ),
        },
        {
            type: "cms.GridPage",
            component: dynamic(() => import("./components/wagtail/GridPage")),
            fetchData: (...props) =>
                import("./components/wagtail/GridPage").then(({ fetchData }) =>
                    fetchData(...props),
                ),
        },
        {
            type: "cms.ProductPage",
            component: dynamic(
                () => import("./components/wagtail/ProductPage"),
            ),
            fetchData: (...props) =>
                import("./components/wagtail/ProductPage").then(
                    ({ fetchData }) => fetchData(...props),
                ),
        },
        {
            type: "cms.DisplayProductPage",
            component: dynamic(
                () => import("./components/wagtail/DisplayProductPage"),
            ),
        },
        {
            type: "cms.GenericFormPage",
            component: dynamic(
                () => import("./components/wagtail/GenericFormPage"),
            ),
        },
        {
            type: "cms.VisualizerPage",
            component: dynamic(
                () => import("./components/wagtail/VisualizerPage"),
            ),
            fetchData: (...props) =>
                import("./components/wagtail/VisualizerPage").then(
                    ({ fetchData }) => fetchData(...props),
                ),
        },
    ],
    NotFoundPage,
});
